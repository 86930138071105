@font-face {
  font-family: 'avenir_nextregular';
  src: url('../../../assets/fonts/avenirnext/AvenirNext-Regular.woff2')
      format('woff2'),
    url('../../../assets/fonts/avenirnext/AvenirNext-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'avenir_nextbold';
  src: url('../../../assets/fonts/avenirnext/AvenirNext-Bold.woff2')
      format('woff2'),
    url('../../../assets/fonts/avenirnext/AvenirNext-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

p.legend {
  background-color: transparent !important;
  color: #fefefe !important;
  font-weight: bold !important;
  padding-top: 100px !important;
  opacity: 1 !important;
  font-size: 18px !important;
  align-self: flex-end !important;
  display: flex !important;
  justify-content: flex-end;
  padding-right: 130px !important;
  font-family: 'avenir_nextbold';
}
.carousel-status {
  display: none;
}

.carousel .slide .legend {
  bottom: 0px;
  left: 60%;
}

@media only screen and (max-width: 768px) {
  .carousel .slide img {
    height: 50vh !important;
  }
}
