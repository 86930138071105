.btn {
  margin-top: 20px !important;
  background-color: #dd1a1b !important;
  border: 'none' !important;
  color: #fff !important;
  width: 50% !important;
  height: 50px !important;
  font-family: 'avenir_nextregular';
  margin-bottom: 10px;
}
.btn:hover {
  color: #fff !important;
}

.input {
  margin-top: 10px !important;
  border: 1px solid #d9d9d9 !important;
  opacity: 1 !important;
  height: 50px;
  text-indent: 10px;
  width: 100%;
  font-family: 'avenir_nextregular';
}
.input:focus {
  background-color: #fff;
}

.textarea {
  margin-top: 10px !important;
  border: 1px solid #d9d9d9 !important;
  opacity: 1 !important;
  /* height: 50px; */
  text-indent: 10px;
  width: 100%;
  font-family: 'avenir_nextregular';
}
.textarea:focus {
  background-color: #fff;
}

label {
  margin-left: 10px;
  font-family: 'avenir_nextregular';
}
.formP {
  font-family: 'avenir_nextbold';
  margin-top: 10px;
  font-size: 40px;
}
.error {
  color: #dd1a1b;
  font-family: 'avenir_nextregular';
  margin-top: 5px;
}
@media only screen and (max-width: 1380px) {
  .formP {
    font-size: 32px !important;
  }
}

@media only screen and (max-width: 780px) {
  .formP {
    font-size: 28px;
  }
}
